body {
  margin: 0;
  padding: 30px 120px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

@media only screen and (max-width: 1024px) {
   body {
      padding: 20px;
   }
}
 
@media only screen and (max-width: 768px) {
   body {
      padding: 15px;
   }
}
